import {useState, useReducer, useEffect} from 'react';
import Layout from '../../modules/components/layout/Layout.js';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import * as Elements from '../../modules/components/elements.js';
import {Input} from '../../modules/components/TextInput.js';
import {Button} from '../../modules/components/Button.js';
import {Checkbox} from '../../modules/components/Checkbox.js';
import DataField from '../../modules/components/DataField.js';
import Section from '../../modules/components/Section.js';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFileArrowDown,
  faCheck,
  faCircleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {CSVLink} from 'react-csv';
import {createLabels} from '../../lib/labelGenerator.js';
import {GeneratePurchaseOrderPDF} from './components/GeneratePurchaseOrderPDF.jsx';
import {prettyDate} from '../../modules/components/elements.js';
import DisplayDate from '../../modules/components/DisplayDate.js';
import {ExpenseTable} from './components/ExpenseTable.jsx';

const handleNewLabelData = (state, value) => {
  return {
    ...state,
    ...value,
  };
};
const handleLineSelectionReducer = (state, value) => {
  const index = state.indexOf(value);
  if (index >= 0) {
    state.splice(index, 1);
    return [...state];
  }
  return [value, ...state];
};
const handleTransactionUpdateRequestReducer = (state, value) => {
  let temp = state;
  if (temp === null) {
    temp = {expShipDateReq: []};
  }
  if (value.expShipDateReq !== undefined) {
    for (let i = 0; i < value.expShipDateReq.length; i++) {
      temp.expShipDateReq.push(value.expShipDateReq[i]);
    }
  }
  return {...temp};
};

const labelTypes = [
  {
    key: 'upc',
    displayName: 'UPC',
    identifier: 'UPC-2.5x1',
  },
  {
    key: 'pack',
    displayName: 'Pack',
    identifier: 'PACK-3x2',
  },
  {
    key: 'masterPack',
    displayName: 'Master Pack',
    identifier: 'MASTER-3x2',
  },
  {
    key: 'tote',
    displayName: 'Tote',
    identifier: 'TOTE-3x2',
  },
];
const itemBarcodeData = {};
const oneDay_milliseconds = 86400000;

const ITEM_PRIORITY_STATUS = {
  URGENT_OVERDUE_VERY_HIGH_PRIORITY: 0,
  URGENT_HIGH_PRIORITY: 1,
  OVERDUE_HIGH_PRIORITY: 2,
  NORMAL_PRIORITY: 3,
  LOW_PRIORITY: 4,
  CLOSED: 5,
};

/**
 *
 * @param props
 * @constructor
 */
const POLine = props => {
  /*
   * Functions
   */

  const validateExpShipDate = date => {
    // The number of days in the past or future that a ship date can be set to.
    // The vendor can't enter an expected ship date more than this many days in
    // the past or future.
    //
    // We don't need the future calculation to be accurate. We just don't want the
    // vendor to enter a date that's some crazy date in the future, so we can
    // assume that a year is always 365 days for simplicity sake.
    const allowedDaysPast = 3;
    const allowedDaysFuture = 365;
    const newExpShipDate = new Date(date);
    const now = new Date().setHours(0, 0, 0, 0);

    const daysPastDate = new Date(now - oneDay_milliseconds * allowedDaysPast);
    const daysFutureDate = new Date(
      now + oneDay_milliseconds * allowedDaysFuture
    );

    if (newExpShipDate < daysPastDate) {
      alert(
        `You cannot enter a date that is more than ${allowedDaysPast} days in the past.`
      );
      return false;
    }

    if (newExpShipDate > daysFutureDate) {
      alert(
        `You cannot enter a date that is more than ${allowedDaysFuture} days in the future.`
      );
      return false;
    }

    return true;
  };

  /**
   * Fires when the Expected Ship Date field on a PO is changed
   *
   * @param params
   */
  const poDetailDateChange = params => {
    if (params.syncedWithNS === false && params.value) {
      const url = '/api/v1/ns/po/setExpectedShipDate';

      const data = {
        orderNumber: params.poNumber,
        mainline: false,
        updates: [
          {
            lineNumber: params.lineNumber,
            newValue: params.value,
            itemId: params.itemId,
            note: params.oldValue ? `Previous Date: ${params.oldValue}` : '',
          },
        ],
      };

      return fetch(url, {
        method: 'POST',
        headers: new Headers({'Content-Type': 'application/json'}),
        body: JSON.stringify(data),
      })
        .then(async result => {
          return await result.json();
        })
        .then(result => {
          props.handleSetExpShipDate(params.value);
          return new Promise(resolve => resolve(result));
        });
    } else if (!params.value) {
      return new Promise(resolve =>
        resolve({
          success: false,
          error: 'Invalid Date',
        })
      );
    }
  };

  /**
   *
   * @param params
   * @returns {*}
   */
  const updateNSExpShipDate = value => {
    const params = {};
    params.value = Elements.prettyDate(value);
    params.oldValue = Elements.prettyDate(expectedShipDate);
    const newParams = {
      lineNumber: props.lineNumber,
      poNumber: props.poNumber,
      itemId: props.item.id,
      syncedWithNS: false,
      ...params,
    };
    console.log('Update params', newParams);

    return poDetailDateChange(newParams);
  };

  const toggleLineSelection = () => {
    if (!isVisible) {
      return;
    }

    setIsSelected(!isSelected);
    if (props.handleLineSelection) {
      props.handleLineSelection();
    }
  };

  /*
   * Setup and States
   */

  let expectedShipDate = props.expectedShipDate;
  let expShipDatePORequest = props.expShipDatePORequest;
  const [upcSelected, setUPCSelected] = useState(false);
  const [packSelected, setPackSelected] = useState(false);
  const [masterSelected, setMasterPackSelected] = useState(false);
  const [toteSelected, setToteSelected] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [rowHighlight, setRowHighlight] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  let urgent,
    overdue,
    normal,
    closed = false;

  if (
    props.priority === ITEM_PRIORITY_STATUS.URGENT_OVERDUE_VERY_HIGH_PRIORITY
  ) {
    urgent = true;
    overdue = true;
  } else if (props.priority === ITEM_PRIORITY_STATUS.URGENT_HIGH_PRIORITY) {
    urgent = true;
  } else if (props.priority === ITEM_PRIORITY_STATUS.OVERDUE_HIGH_PRIORITY) {
    overdue = true;
  } else if (props.priority === ITEM_PRIORITY_STATUS.NORMAL_PRIORITY) {
    normal = true;
  } else if (props.priority === ITEM_PRIORITY_STATUS.CLOSED) {
    closed = true;
  }

  const updateLabelSelection = () => {
    const newLabelData = [];

    if (isVisible) {
      if (upcSelected && itemBarcodeData[props.item.id].upc.requiresLabel) {
        const labelData = {
          ...itemBarcodeData[props.item.id],
          quantity: 1,
          barcode: itemBarcodeData[props.item.id].upc.barcode,
          labelKey: 'UPC',
          labelSize: '2.5x1',
          printQuantity: props.quantityOrdered,
        };
        newLabelData.push(labelData);
      }

      if (packSelected && itemBarcodeData[props.item.id].pack.requiresLabel) {
        const labelData = {
          ...itemBarcodeData[props.item.id],
          quantity: itemBarcodeData[props.item.id].pack.quantity,
          barcode: itemBarcodeData[props.item.id].pack.barcode,
          totalPrintCount: itemBarcodeData[props.item.id].pack.totalPrintCount,
          labelKey: 'PACK',
          labelSize: '3x2',
          displayPOQRCode: itemBarcodeData[props.item.id].pack.displayPOQRCode,
          printQuantity: props.quantityOrdered,
        };
        newLabelData.push(labelData);
      }

      if (
        masterSelected &&
        itemBarcodeData[props.item.id].masterPack.requiresLabel
      ) {
        const labelData = {
          ...itemBarcodeData[props.item.id],
          quantity: itemBarcodeData[props.item.id].masterPack.quantity,
          barcode: itemBarcodeData[props.item.id].masterPack.barcode,
          totalPrintCount:
            itemBarcodeData[props.item.id].masterPack.totalPrintCount,
          labelKey: 'MASTER',
          labelSize: '3x2',
          displayPOQRCode: itemBarcodeData[props.item.id].pack.displayPOQRCode,
          printQuantity: props.quantityOrdered,
        };
        newLabelData.push(labelData);
      }

      if (toteSelected && itemBarcodeData[props.item.id].tote.requiresLabel) {
        const labelData = {
          ...itemBarcodeData[props.item.id],
          quantity: itemBarcodeData[props.item.id].tote.quantity,
          barcode: itemBarcodeData[props.item.id].tote.barcode,
          totalPrintCount: itemBarcodeData[props.item.id].tote.totalPrintCount,
          labelKey: 'TOTE',
          labelSize: '3x2',
          printQuantity: props.quantityOrdered,
        };
        newLabelData.push(labelData);
      }
    }

    props.getLabelData(props.item, newLabelData);
  };

  /*
   * Effects
   *
   * Remember that effects occur in the order that they're created here.
   */

  useEffect(() => {
    if ('selectAllUPC' in props.selectAllLabel) {
      setUPCSelected(props.selectAllLabel.selectAllUPC);
    } else if ('selectAllPack' in props.selectAllLabel) {
      setPackSelected(props.selectAllLabel.selectAllPack);
    } else if ('selectAllMasterPack' in props.selectAllLabel) {
      setMasterPackSelected(props.selectAllLabel.selectAllMasterPack);
    } else if ('selectAllTote' in props.selectAllLabel) {
      setToteSelected(props.selectAllLabel.selectAllTote);
    }
  }, [props.selectAllLabel]);

  useEffect(() => {
    if (!isVisible) {
      return;
    }
    if (isSelected !== props.allLinesSelected.state) {
      props.handleLineSelection();
    }
    setIsSelected(props.allLinesSelected.state);
  }, [props.allLinesSelected]);

  useEffect(() => {
    setRowHighlight(props.highlightClass);
  }, [props.highlightClass]);

  useEffect(() => {
    setIsVisible(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (
      props.setExpShipDate !== '' &&
      isSelected &&
      props.setExpShipDate !== expectedShipDate
    ) {
      console.log(props.setExpShipDate);
      updateNSExpShipDate(props.setExpShipDate)
        .then(result => {
          console.log(result);
          expShipDatePORequest = props.setExpShipDate;
          console.log(props.setExpShipDate);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, [props.setExpShipDate]);

  useEffect(() => {
    updateLabelSelection();
  }, [isVisible]);

  useEffect(updateLabelSelection, [
    upcSelected,
    packSelected,
    masterSelected,
    toteSelected,
  ]);

  let expectedShipDate_string = '';
  if (expShipDatePORequest && expectedShipDate !== expShipDatePORequest) {
    expectedShipDate = expShipDatePORequest;
  }
  if (expectedShipDate) {
    expectedShipDate_string = expectedShipDate;
  }

  const upcCheckbox = props.upc.requiresLabel ? (
    <Checkbox
      checked={upcSelected}
      labeltype={'upc'}
      onChange={() => setUPCSelected(!upcSelected)}
    />
  ) : (
    '-'
  );
  const packCheckbox = props.pack.requiresLabel ? (
    <Checkbox
      checked={packSelected}
      labeltype={'pack'}
      onChange={() => setPackSelected(!packSelected)}
    />
  ) : (
    '-'
  );
  const masterPackCheckbox = props.masterPack.requiresLabel ? (
    <Checkbox
      checked={masterSelected}
      labeltype={'masterPack'}
      onChange={() => setMasterPackSelected(!masterSelected)}
    />
  ) : (
    '-'
  );
  const toteCheckbox = props.tote.requiresLabel ? (
    <Checkbox
      checked={toteSelected}
      labeltype={'tote'}
      onChange={() => setToteSelected(!toteSelected)}
    />
  ) : (
    '-'
  );
  const visibleClass = isVisible ? '' : 'hidden';

  let shipWindow;
  if (props.shipWindow.start && props.shipWindow.end) {
    shipWindow = `${props.shipWindow.start} - ${props.shipWindow.end}`;
  } else if (props.shipWindow.start) {
    shipWindow = `${props.shipWindow.start} - None`;
  } else if (props.shipWindow.end) {
    shipWindow = `None - ${props.shipWindow.end}`;
  } else {
    shipWindow = 'None';
  }

  return (
    <tr
      className={`${visibleClass} ${rowHighlight}`}
      key={props.index}
      itemID={props.item.id}
    >
      <td className={'lineSelect'}>
        <Checkbox checked={isSelected} onChange={toggleLineSelection} />
      </td>
      <td>
        <div className={'itemnumber'}>{props.item.number}</div>
        <div className={'itemDescription'}>{props.displayName}</div>
      </td>
      <td>
        <Input
          type={'date'}
          defaultValue={expectedShipDate_string}
          dataValidation={validateExpShipDate}
          itemNumber={props.item.number}
          handleChange={updateNSExpShipDate}
          showDateIndicator={true}
          shipDateChanged={expShipDatePORequest ? 1 : 0}
          shipWindow={props.shipWindow}
        />
      </td>
      <td>{shipWindow}</td>
      <td className="text-center">{props.quantityOrdered || 0}</td>
      <td className="text-center">
        {props.qty_received + props.qty_in_transit || 0}
      </td>
      <td className="text-center">
        <div style={{display: 'inline-grid'}}>
          {urgent ? (
            <span className={'badgeCapsule darkorange'}>URGENT</span>
          ) : (
            ''
          )}
          {overdue ? (
            <span className={'badgeCapsule orange'}>OVERDUE</span>
          ) : (
            ''
          )}
          {normal ? (
            <span className={'badgeCapsule lightblue'}>NORMAL</span>
          ) : (
            ''
          )}
          {closed ? (
            <span className={'badgeCapsule lightgray'}>CLOSED</span>
          ) : (
            ''
          )}
        </div>
      </td>
      {props.labelsNeeded.upc ? (
        <td className="mini text-center">{upcCheckbox}</td>
      ) : (
        ''
      )}
      {props.labelsNeeded.pack ? (
        <td className="mini text-center">{packCheckbox}</td>
      ) : (
        ''
      )}
      {props.labelsNeeded.masterPack ? (
        <td className="mini text-center">{masterPackCheckbox}</td>
      ) : (
        ''
      )}
      {props.labelsNeeded.tote ? (
        <td className="mini text-center">{toteCheckbox}</td>
      ) : (
        ''
      )}
    </tr>
  );
};

/**
 *
 */
function PODetail(props) {
  /*
   * States
   */
  const [poData, setPOData] = useState(null);
  const [transactionUpdateRequests, setTransactionUpdateRequests] = useReducer(
    handleTransactionUpdateRequestReducer,
    null
  );
  const [confirmPOButtonDisabled, setConfirmPOButtonDisabled] = useState(false);
  const [confirmPOButtonText, setConfirmPOButtonText] = useState('Confirm PO');
  const [lineFilter, setLineFilter] = useState('');
  const [lineCheckboxes, setLineCheckboxes] = useState(false);
  const [labelsNeeded, setLabelsNeeded] = useState({
    upc: false,
    pack: false,
    masterPack: false,
    tote: false,
  });
  const [selectAllLabel, setSelectAllLabel] = useState({
    selectAllUPC: false,
    selectAllPack: false,
    selectAllMasterPack: false,
    selectAllTote: false,
  });
  const [labelData, setLabelData] = useReducer(handleNewLabelData, {});
  const [selectedLines, setSelectedLines] = useReducer(
    handleLineSelectionReducer,
    []
  );
  const [allLinesSelected, setAllLinesSelected] = useState({state: false});
  const [multilineVisibility, setMultilineVisibility] = useState(false);
  const [multilineShipDate, setMultilineShipDate] = useState(
    prettyDate(new Date())
  );
  const [applyNewShipDate, setApplyNewShipDate] = useState(false);

  useEffect(() => {
    setMultilineVisibility(selectedLines.length > 1);
  }, [selectedLines]);

  // Whenever this field is set to true, set it to false after the next render
  useEffect(() => {
    setApplyNewShipDate(false);
  }, [applyNewShipDate]);

  /*
   * Functions
   */

  /**
   * Updates the page when the search changes what's visible
   */
  const handleSearchChange = () => {
    setLineFilter($('#poLines_search').val());
  };

  /**
   * Attempts to confirm the PO in Netsuite
   */
  const confirmPo = () => {
    setConfirmPOButtonText('Confirming PO...');
    setConfirmPOButtonDisabled(true);

    $.post({
      url: `/api/v1/ns/confirm/mainline/po/${poNumber}`,
      beforeSend: () => {},
    })
      .then(result => {
        if (result.success) {
          const newPOData = JSON.parse(JSON.stringify(poData));
          newPOData.header.confirmedByVendor = true;
          setPOData(newPOData);
          setConfirmPOButtonDisabled(true);
        } else {
          setConfirmPOButtonDisabled(false);
        }
      })
      .catch(() => {
        setConfirmPOButtonDisabled(false);
      });
  };

  const getLabelData = (item, newLabelData) => {
    setLabelData({[item.id]: newLabelData});
  };

  const selectAllLines = () => {
    setAllLinesSelected({state: true});
  };

  const selectNoLines = () => {
    setAllLinesSelected({state: false});
  };

  const changeSelectedLineDates = () => {
    console.log('Setting select line dates to', multilineShipDate);
    setApplyNewShipDate(true);
  };

  const updateMultilineDateValue = date => {
    setMultilineShipDate(date);
    return new Promise(resolve => resolve({successes: [true]}));
  };

  /*
   * Setup
   */

  const poNumber = props.location.pathname.split('/').pop();
  let content = <div></div>;
  let hiddenResults = 0;

  /*
   * Rendering
   */

  if (poNumber === '' || poNumber === 'purchaseorder') {
    return <Redirect to={'/'} />;
  }

  if (!poData || !transactionUpdateRequests) {
    /*
     * Request the Transaction Update Request data from the server
     */
    if (!transactionUpdateRequests) {
      const url = `/api/v1/ns/getTURs/${poNumber}`;
      $.get({url}).then(transUpdateRequests => {
        setTransactionUpdateRequests(transUpdateRequests.updates);
      });
    }

    /*
     * Request the PO data from the server
     */
    if (!poData) {
      const url = `/api/v1/ns/po/${poNumber}`;
      $.get({url}).then(fetchedPOData => {
        console.log(fetchedPOData);

        fetchedPOData.lines = fetchedPOData.lines.sort((a, b) => {
          if (a.item.number < b.item.number) {
            return -1;
          } else if (a.item.number > b.item.number) {
            return 1;
          }
          return 0;
        });

        setPOData(fetchedPOData);
      });
    }
    content = <div>Loading...</div>;

    /*
     * Render the PO Data
     */
  } else if (poData.error === '') {
    const lineData = [
      <tr className="header sticky" key="header">
        <th></th>
        <th className="dataLabel">Item</th>
        <th className="dataLabel">
          Expected
          <br />
          Ship Date
        </th>
        <th className="dataLabel">Ship Window</th>
        <th className="dataLabel text-center">Ordered</th>
        <th className="dataLabel text-center">Shipped</th>
        <th className="dataLabel text-center">Priority</th>
        {labelTypes.map((element, index) => {
          return labelsNeeded[element.key] ? (
            <th className="dataLabel mini text-center" key={index}>
              {element.displayName}
            </th>
          ) : (
            ''
          );
        })}
      </tr>,
    ];

    // Go through all the PO lines once to track states of the checkboxes that
    // are defined in the labelTypes object created above.
    //
    // Note that the checkbox data is keyed on the item ID. This is okay as long
    // as an item isn't added to a PO twice in Netsuite. That shouldn't be
    // allowed, but if it is, it could mess with how labels are printed here.
    if (!lineCheckboxes) {
      const initialCheckboxStates = {};
      const initialCheckboxesNeeded = {};
      for (const index in poData.lines) {
        const line = poData.lines[index];
        const lineId = line.item.id;

        initialCheckboxStates[lineId] = {};

        labelTypes.forEach(element => {
          const cannotBePrinted =
            (element.key === 'upc' && !line.upc.requiresLabel) ||
            (element.key === 'pack' && !line.pack.requiresLabel) ||
            (element.key === 'masterPack' && !line.masterPack.requiresLabel) ||
            (element.key === 'tote' && !line.tote.requiresLabel);

          if (!cannotBePrinted) {
            initialCheckboxStates[lineId][element.key] = false;
            initialCheckboxesNeeded[element.key] = true;
          }
        });
      }
      setLabelsNeeded(initialCheckboxesNeeded);
      setLineCheckboxes(initialCheckboxStates);
    }

    /*
     * Render all the PO Lines
     */
    let visibleRowIndex = 0;
    let showExpShipDateNotice = false;
    for (const index in poData.lines) {
      const line = poData.lines[index];

      const expShipDate_date = line.expectedShipDate
        ? new Date(line.expectedShipDate)
        : null;

      let i = transactionUpdateRequests.expShipDateReq.length - 1;
      let expShipDatePORequest = null;

      // Look for most recent ship date update
      while (i >= 0) {
        if (
          transactionUpdateRequests.expShipDateReq[i].values
            .custrecord_tur_fieldid === 'custcol_exp_ship_date' &&
          transactionUpdateRequests.expShipDateReq[i].values
            .custrecord_tur_item['0'].value === line.item.id.toString()
        ) {
          expShipDatePORequest =
            transactionUpdateRequests.expShipDateReq[i].values
              .custrecord_tur_newvalue;
          showExpShipDateNotice = true;
          // this will break the loop
          i = -1;
        }
        i--;
      }

      const searchMatch =
        line.item.number.toLowerCase().indexOf(lineFilter.toLowerCase()) >= 0 ||
        line.displayName.toLowerCase().indexOf(lineFilter.toLowerCase()) >= 0 ||
        line.shipWindow.start.indexOf(lineFilter.toLowerCase()) >= 0;
      let isVisible = true;

      if (!searchMatch) {
        hiddenResults++;
        // continue;
        isVisible = false;
      }

      let rowHighlightClass = '';
      if (isVisible && ++visibleRowIndex % 2 === 0) {
        rowHighlightClass = 'bg-accent';
      }

      // All lines are generated in the CSV regardless of visibility
      itemBarcodeData[line.item.id] = {
        barcode: null,
        upc: line.upc,
        pack: line.pack,
        masterPack: line.masterPack,
        tote: line.tote,
        coo: line.coo,
        coo_long: line.coo_long,
        transId: poNumber,
        itemNumber: line.item.number,
        displayName: line.displayName,
        revision: line.revision,
        mpn: line.mpn,
      };

      const updateLineDate =
        isVisible &&
        selectedLines.indexOf(line.item.id) >= 0 &&
        applyNewShipDate
          ? multilineShipDate
          : '';

      if (!isVisible) {
        // console.log(line.item.id, "Not applying new ship date. Not visible");
      } else if (!applyNewShipDate) {
        // console.log(line.item.id, "Not applying new ship date. Application not triggered");
      } else if (selectedLines.indexOf(line.item.id) < 0) {
        // console.log(line.item.id, "Not applying new ship date. Item not selected");
      } else {
        // console.log(line.item.id, "Applying new ship date.", updateLineDate);
      }

      lineData.push(
        <POLine
          index={index}
          highlightClass={rowHighlightClass}
          key={index}
          visible={isVisible}
          item={line.item}
          displayName={line.displayName}
          quantityOrdered={line.qty.ordered}
          qty_in_transit={line.qty.inTransit}
          qty_received={line.qty.received}
          upc={line.upc}
          pack={line.pack}
          masterPack={line.masterPack}
          tote={line.tote}
          priority={line.priority}
          expectedShipDate={expShipDate_date}
          expShipDatePORequest={expShipDatePORequest}
          shipWindow={line.shipWindow}
          selectAllLabel={selectAllLabel}
          labelsNeeded={labelsNeeded}
          getLabelData={getLabelData}
          applyNewShipDate={applyNewShipDate}
          poNumber={poNumber}
          lineNumber={line.lineNumber}
          allLinesSelected={allLinesSelected}
          handleLineSelection={() => {
            setSelectedLines(line.item.id);
          }}
          handleSetExpShipDate={newExpShipDate => {
            const obj = {
              expShipDateReq: [
                {
                  values: {
                    custrecord_tur_fieldid: 'custcol_exp_ship_date',
                    custrecord_tur_item: [{value: line.item.id.toString()}],
                    custrecord_tur_newvalue: newExpShipDate,
                  },
                },
              ],
            };
            setTransactionUpdateRequests(obj);
          }}
          setExpShipDate={updateLineDate}
        />
      );
    }

    // If the length is 1, the only line on is the header
    if (lineData.length === 1) {
      lineData.push(
        <tr key="noitems">
          <td colSpan="100%">
            This PO does not contain any items pending receipt
          </td>
        </tr>
      );
    }

    // If there are any filtered results, notify the user
    if (hiddenResults > 0) {
      lineData.push(
        <tr className={'filterWarning'} key="filteredLines">
          <td colSpan="100%">
            Hiding {hiddenResults} lines that don&apos;t match your search
            criteria.
          </td>
        </tr>
      );
    }

    /*
     * Handle the Confirmation button display
     */
    let vendorConfirmation;
    const venConfirmed = poData.header.confirmedByVendor;
    if (venConfirmed) {
      vendorConfirmation = (
        <DataField
          label="Confirmed by Vendor"
          labelClass="text-center"
          value={
            <div>
              <FontAwesomeIcon icon={faCheck} />
              &nbsp;Confirmed
            </div>
          }
        ></DataField>
      );
    } else {
      vendorConfirmation = (
        <DataField
          className="text-center"
          label="Confirmed by Vendor"
          value={
            <Button
              disabled={confirmPOButtonDisabled}
              mini={true}
              onClick={e => {
                confirmPo(e);
              }}
            >
              {confirmPOButtonText}
            </Button>
          }
        ></DataField>
      );
    }

    // Add a notice about the expected ship date changes.
    let expShipDateNotice = '';
    if (showExpShipDateNotice) {
      expShipDateNotice = (
        <tr className={'filterWarning'}>
          <td className={'flex-item'} colSpan={'100%'}>
            <span>
              <FontAwesomeIcon icon={faCircleExclamation} />
            </span>{' '}
            These expected ship dates have not yet been confirmed by a Tekton
            representative.
          </td>
        </tr>
      );
    }

    /*
     * Render the entire page
     */

    // The label generation section only appears if labels can be printed
    const showLabelGeneration =
      labelsNeeded.upc ||
      labelsNeeded.pack ||
      labelsNeeded.masterPack ||
      labelsNeeded.tote;
    let labelGenerationSection;
    if (showLabelGeneration) {
      labelGenerationSection = (
        <Section
          title="Label Generation"
          id="poDetails_labelGeneration"
          className={`${multilineVisibility ? '' : 'sticky'}`}
        >
          <div className={'flex'}>
            <div id={'masterLabelControls'}>
              <table>
                <tbody>
                  {labelsNeeded.upc ? (
                    <tr>
                      <td>UPC</td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllUPC: true})
                          }
                        >
                          Select All
                        </Button>
                      </td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllUPC: false})
                          }
                        >
                          Select None
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {labelsNeeded.pack ? (
                    <tr>
                      <td>Pack</td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllPack: true})
                          }
                        >
                          Select All
                        </Button>
                      </td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllPack: false})
                          }
                        >
                          Select None
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {labelsNeeded.masterPack ? (
                    <tr>
                      <td>Master Pack</td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllMasterPack: true})
                          }
                        >
                          Select All
                        </Button>
                      </td>
                      <td>
                        <Button
                          label={'Select None'}
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllMasterPack: false})
                          }
                        >
                          Select None
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                  {labelsNeeded.tote ? (
                    <tr>
                      <td>Tote</td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllTote: true})
                          }
                        >
                          Select All
                        </Button>
                      </td>
                      <td>
                        <Button
                          mini={true}
                          inverted={true}
                          onClick={() =>
                            setSelectAllLabel({selectAllTote: false})
                          }
                        >
                          Select None
                        </Button>
                      </td>
                    </tr>
                  ) : (
                    ''
                  )}
                </tbody>
              </table>
            </div>
            <GenerateButtonsSection labelData={labelData} />
          </div>
        </Section>
      );
    }

    // The multi-line selection section does not need to be display for single line orders.
    let multilineSection;
    if (poData.lines.length > 1) {
      multilineSection = (
        <Section
          title={'Multi-line Selection'}
          id={'poDetails_multiline'}
          className={`${multilineVisibility ? 'sticky' : ''}`}
        >
          <div className={'flex flex-wrap content-start gap-2.5'}>
            <DataField label={'Select All'}>
              <Button mini={true} inverted={true} onClick={selectAllLines}>
                Select All
              </Button>
            </DataField>

            <DataField label={'Select None'}>
              <Button mini={true} inverted={true} onClick={selectNoLines}>
                Select None
              </Button>
            </DataField>
            <DataField
              label={'Change Expected Ship Date of all selected lines to'}
            >
              <Input
                defaultValue={multilineShipDate}
                showDateIndicator={false}
                type={'date'}
                handleChange={updateMultilineDateValue}
              />
              <Button mini={true} onClick={changeSelectedLineDates}>
                Update Selected Lines
              </Button>
            </DataField>
          </div>
        </Section>
      );
    }

    const showItemsTable = poData.lines.length > 0;
    const showExpenseTable = poData.expenseLines.length > 0;

    content = (
      <div>
        <Section title="Details">
          <div className={'flex flex-wrap content-start gap-2.5'}>
            <DataField
              label="PO Date"
              value={
                <DisplayDate disabled={true}>{poData.header.date}</DisplayDate>
              }
            />
            <DataField
              label="CSV"
              value={
                <CSVLink
                  filename={`Tekton_${poNumber}`}
                  data={generateCSVData({poNumber, poData})}
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFileArrowDown} /> Download
                </CSVLink>
              }
            />
            <DataField
              label="PDF"
              value={
                <GeneratePurchaseOrderPDF poNumber={poNumber} poData={poData} />
              }
            />
            <DataField
              label="Manufacturer"
              value={poData.header.manufacturer}
            />
            <DataField value={vendorConfirmation} />
          </div>
        </Section>
        {showItemsTable && (
          <Elements.SectionDeprecated
            title="Lines"
            sectionPrefix="poLines"
            searchChange={handleSearchChange}
          >
            <table id={'poLines'} className={'w-full'}>
              <tbody>
                {lineData}
                {expShipDateNotice}
              </tbody>
            </table>
          </Elements.SectionDeprecated>
        )}
        {(showExpenseTable || showLabelGeneration) && (
          <div className={'flex gap-5'}>
            {showExpenseTable && (
              <div className={'flex-1'}>
                <ExpenseTable expenses={poData.expenseLines}></ExpenseTable>
              </div>
            )}
            {showLabelGeneration && (
              <div className={'flex-1'}>{labelGenerationSection}</div>
            )}
          </div>
        )}
        {multilineSection}
      </div>
    );
  } else {
    content = (
      <div>
        <Section title={'Not Found'}>
          <p>{poData.error}</p>
        </Section>
      </div>
    );
  }

  return (
    <Layout>
      <h1>Purchase Order {poNumber}</h1>
      {content}
    </Layout>
  );
}

/**
 * The section storing the label generation buttons.
 * @param {object} props
 * @param {array} labelData
 * @returns {JSX.Element}
 */
const GenerateButtonsSection = ({labelData}) => (
  <div className={'flex items-end basis-full flex-wrap flex-col justify-start'}>
    <div className={'flex flex-row items-center'}>
      <div className={'text-right  mx-2'}>
        Generate one of each label selected.
      </div>
      <GenerateLabelButton labelData={labelData} />
    </div>
    <div className={'flex flex-row items-center pt-2'}>
      <div className={'text-right mx-2'}>
        Generate selected Pack / Master Pack / Tote labels needed for the order.
        <br />
        Extra labels will print if more than 30 of a label is needed.
      </div>
      <GenerateLabelButton
        printAll={true}
        labelData={Object.entries(labelData).reduce(
          (previousValue, [itemId, labelArray]) => {
            // UPCs must be excluded from this button.
            const filteredArray = labelArray.filter(
              label => label.labelKey !== 'UPC'
            );

            if (filteredArray.length === 0) {
              return previousValue;
            }

            previousValue[itemId] = filteredArray;
            return previousValue;
          },
          {}
        )}
      />
    </div>
  </div>
);

/**
 *
 * @param {object} props
 * @param {array} labelData
 * @param {boolean} [printAll]
 * @param {string} [className]
 * @returns {JSX.Element}
 */
const GenerateLabelButton = ({labelData, printAll = false, className}) => {
  const [loading, setLoadingState] = useState(false);

  const labelCount = Object.keys(labelData).reduce((acc, itemId) => {
    return acc + labelData[itemId].length;
  }, 0);

  const noLabelsSelected = labelCount === 0;

  const clickHandler = async () => {
    setLoadingState(true);

    await generateLabels({labelData, printAll});

    setLoadingState(false);
  };

  return (
    <Button
      disabled={noLabelsSelected || loading}
      className={className}
      onClick={clickHandler}
    >
      {loading ? 'Generating...' : 'Generate'}
    </Button>
  );
};

/**
 * Generates all item labels that have been selected
 * @param {array} labelData
 * @param {boolean} [printAll]
 */
const generateLabels = async ({labelData, printAll}) => {
  let combinedLabelData = [];

  // Go through the line checkboxes and build the necessary data object for
  // the label generator based on which are checked.
  for (const itemId in labelData) {
    combinedLabelData = combinedLabelData.concat(labelData[itemId]);
  }

  if (!combinedLabelData.length) {
    return;
  }

  combinedLabelData.sort((a, b) => {
    if (a.itemNumber < b.itemNumber) {
      return -1;
    } else if (a.itemNumber > b.itemNumber) {
      return 1;
    }
    return 0;
  });

  await createLabels({
    labelList: combinedLabelData,
    printAll,
  }).catch(e => {
    console.log(e);
  });
};

const generateCSVData = ({poNumber, poData}) => {
  const csvData = [];

  csvData.push([
    'PO Number',
    'Date',
    'Item Number',
    'Description',
    'Revision',
    'Quantity',
    'Pack Quantity',
    'Master Pack Quantity',
    'Rate',
    'Amount',
    'Urgent',
    'Notes',
    'Expected Ship Date',
    'Shipping Window Start',
    'Shipping Window End',
    'Supplier Price',
    'Ext. Supplier Price',
    'Manufacturer Part Number',
  ]);

  for (const line of poData.lines) {
    csvData.push([
      poNumber,
      Elements.prettyDate(poData.header.date),
      line.item.number,
      line.displayName,
      line.revision,
      line.qty.ordered,
      line.pack.quantity,
      line.masterPack.quantity,
      line.rateUSD,
      line.amount,
      line.urgent,
      line.notes,
      line.expectedShipDate
        ? Elements.prettyDate(new Date(line.expectedShipDate) || null)
        : '',
      Elements.prettyDate(line.shipWindow.start),
      Elements.prettyDate(line.shipWindow.end),
      line.supplierPrice,
      line.supplierPriceExt,
      line.mpn,
    ]);
  }

  return csvData;
};

export default PODetail;
